/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Sunreef Yachts Sells First Sunreef 35M Eco Electric Sailing Catamaran"), "\n", React.createElement(_components.h2, null, "Sale Announcement"), "\n", React.createElement(_components.p, null, "Sunreef Yachts has successfully sold the first unit of its Sunreef 35M Eco series, marking a significant milestone in the luxury yacht market. The sale was facilitated by Y.CO, a renowned yacht brokerage firm. This development underscores Sunreef Yachts' commitment to expanding its eco-friendly offerings."), "\n", React.createElement(_components.h2, null, "Design and Features"), "\n", React.createElement(_components.p, null, "The Sunreef 35M Eco is crafted to deliver an emissions-free cruising experience, seamlessly blending advanced green technology with luxury. This catamaran is a testament to Sunreef's dedication to sustainability, featuring electric propulsion systems that enable silent and environmentally friendly navigation."), "\n", React.createElement(_components.h2, null, "Market Impact"), "\n", React.createElement(_components.p, null, "The introduction of the Sunreef 35M Eco addresses the increasing demand for sustainable luxury yachts. This model is poised to attract environmentally conscious buyers who seek high-performance sailing options without sacrificing luxury."), "\n", React.createElement(_components.h2, null, "Industry Trends"), "\n", React.createElement(_components.p, null, "The sale of the Sunreef 35M Eco is part of a broader trend in the yachting industry towards sustainable practices. Many manufacturers are now exploring electric and hybrid propulsion systems to meet the growing demand for eco-friendly solutions."), "\n", React.createElement(_components.h2, null, "References"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://sunreef-yachts.com/en/news/sunreef-superyacht-expansion-first-sunreef-35m-eco-sold/"
  }, "Sunreef Yachts Official Announcement")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://marineindustrynews.co.uk/sunreef-yachts-sells-first-emission-free-sailing-catamaran/"
  }, "Marine Industry News")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.boatinternational.com/yachts/news/sunreef-yachts-eco-sailing-catamaran-yacht-first-unit-sale"
  }, "Boat International")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
